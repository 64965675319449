import ctaImg1 from 'website/assets/shop-finance-image.png'
import ctaImg2 from 'website/assets/woman-enjoying-her-financially-independence-while-buying-car.png'

const businessName = 'Car N Drive'
const fullStateName = 'Burlington, New Jersey'
const shortStateName = 'NJ'

const assets: Record<string, string> = {
  ctaImg1,
  ctaImg2,
  block1Text1: `${businessName} guarantees closing within 30 minutes assuming financing is already cleared. On average, a consumer spends over 5 hours on the lot of a traditional dealership before driving off with the vehicle.`,
  block2Text1: `${businessName} offers one of the most extensive selections of used cars in ${fullStateName}. Find your dream car among our reliable sedans, spacious SUVs, or sporty convertibles, with new inventory arriving all the time. Visit us to buy used cars and discover why we are known for providing affordable used cars ${shortStateName} area.`,
  block2Text2: `${businessName} makes car buying simple with our commitment to offering affordable prices on all our vehicles across our locations in ${fullStateName}. Whether you’re looking for used car financing, trading in used cars, or securing a used car warranty, we have you covered. Explore our selection for affordable car loans and drive home in your ideal vehicle today.`
}

export default assets
