import ctaImg1 from 'website/assets/viktor-bystrov.png'
import ctaImg2 from 'website/assets/happy-young-couple-enjoying-their-brand-new-car.png'

const assets: Record<string, string> = {
  ctaImg1,
  ctaImg2,
  block1Text1: 'Leading dealership and solutions provider',
  block1Text2: 'Welcome to Car N Drive, your one-stop shop for buying and selling quality used cars. We understand that purchasing a car can be a daunting task, which is why we strive to make the process as simple and stress-free as possible.',
  block1Text3: 'Our marketplace offers a wide variety of makes and models, all thoroughly inspected and certified by our team of experienced technicians. We believe in transparency and honesty, so you can trust that the car you see is the car you get.',
  block2Text1: 'Different Technological Approach',
  block2Text2: 'In addition to traditional offline sales, we also offer an innovative online digital retail tool that allows you to browse and purchase cars from the comfort of your own home. Our user-friendly platform makes it easy to find the perfect car for you and complete the purchase with just a few clicks.',
  block2Text3: 'At Car N Drive, customer satisfaction is our top priority. Our knowledgeable sales team is dedicated to helping you find the right car for your needs and budget. We also offer financing options and after-sales service to ensure that your car-buying experience is as smooth and enjoyable as possible. Thank you for choosing Car N Drive.We look forward to helping you find your dream car.'
}

export default assets
